<template>
  <div id="MessageList">
    <!-- <div class="messageItem">
      <img src="http://moment.wptomo.top/bg.jpg" />
      <div class="mainContent">
        <div>
          <p>名字</p>
          <img class="like" src="~@/assets/image/heart1.png" />
          <p>15:34</p>
        </div>
        <div><div>不到处扫地撒大几十块爱神的箭</div></div>
      </div>
    </div>
    <div class="messageItem">
      <img src="http://moment.wptomo.top/bg.jpg" />
      <div class="mainContent">
        <div>
          <p>名字</p>
          <p class="common">回复<span>爱谁谁:</span>呵呵</p>
          <p>15:34</p>
        </div>
        <div><div>不到处扫地撒大几十块爱神的箭</div></div>
      </div>
    </div> -->
    <!-- <div class="van-list__finished-text">查看更早的消息...</div> -->
    <van-empty v-if="list.length == 0" description="没有消息~" />
    <van-list
      v-model="loading"
      :finished="finished"
      :error.sync="error"
      error-text="请求失败，点击重新加载"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div class="messageItem" v-for="(i,index) in list" :key="index" @click="toDetail(i.data.m_id)">
        <img :src="i.data.content.thumb_avatar" />
        <div class="mainContent">
          <div>
            <p v-if="userBaseInfo.is_self == 1">{{i.data.content.remark}}</p>
            <p v-else>{{i.data.content.from_name}}</p>

            <img v-if="i.data.type == 1" class="like" src="~@/assets/image/heart1.png" />
            <p v-else class="common">
              <span v-if="i.data.content.to_name" style="color:#141414;font-weight:normal">回复</span>
              <span v-if="i.data.content.to_name">{{i.data.content.to_name}}</span>
              <span v-html="i.data.content.content" style="color:#141414;font-weight:normal"></span></p>

            <p>{{Global.formatTimes(i.created_at)}}</p>
          </div>
          <div v-if="i.data.content.moment">
            <div v-if="i.data.content.moment.media_1"><img :src="i.data.content.moment.media_1" /></div>
            <div v-else v-html="i.data.content.moment.content"></div>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
// type 0 评论 1点赞
// import $ from 'jquery'
// import  '../assets/js/jquery.sinaemotion' type:0点赞 1评论
export default {
  name: 'MessageList',
  data(){
    return {
      list: [],
      next_page_url:'',
      loading: false,
      finished: false,
      error:false,
      userBaseInfo:'',
      baseInfo:''
    }
  },
  mounted(){
    this.list = []
    this.baseInfo = this.Storage.get('baseInfo')

    // this.userBaseInfo = this.Storage.get('userBaseInfo')
    this.axios({
      method: 'POST',
      url: '/scrm/auth/wework',
      data:{
        z_id:this.$route.query.z_id,
        userId:this.baseInfo.userId,
        external_userid:this.baseInfo.external_userid
      }
    }).then((res) => {
      if(res.data.code == 0){
        this.Storage.set('userBaseInfo', res.data.data)
        this.userBaseInfo = res.data.data;

        this.axios({
          method: 'POST',
          url: '/scrm/moment/msg',
          data:{
            z_id:this.$route.query.z_id,
            userId:this.baseInfo.userId
          }
        }).then((res) => {
          this.loading = false;
          if(res.data.code == 0){
            this.list = res.data.data.data
            this.next_page_url = res.data.data.next_page_url
            // console.log(this.list,res)
          }
        })
      }
    })


    
  },
  beforeDestroy(){
   
  },
  methods:{
    toDetail(id){
      this.$router.push({
        path: '/CircleDetail',
        name:'CircleDetail',query: {id:id,z_id:this.$route.query.z_id} 
      })
    },
    onLoad() {
      if(this.next_page_url){
        this.axios({
          method: 'POST',
          url: this.next_page_url,
          data:{
            z_id:this.$route.query.z_id,
            userId:this.baseInfo.userId
          }
        }).then((res) => {
          // console.log('>>>')
          this.loading = false;
          if(res.data.code == 0){
            if(res.data.data.data.length){
              this.list.push(...res.data.data.data)
              this.next_page_url = res.data.data.next_page_url
            }
            if(!this.next_page_url || !res.data.data.data.length){
              this.finished = true
            } 
          }
        })
      }else{
        this.loading = false;
      }
    }
  }
}
</script>

<style>
.common img{
  width: 0.16rem;
  height: 0.16rem;
  display: inline-block;
  padding: 0 0.05rem;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.messageItem{
  display: flex;
  margin-top: 0.1rem;
  >.mainContent{
    text-align: left;
    padding-left: 0.1rem;
    padding-right: 0.1rem;
    flex: 1;
    display: flex;
    padding-bottom: 0.1rem;
    border-bottom: 1px solid #f6f6f6;
    >div:nth-child(1){
      flex: 1;
      >p:nth-child(1){
        color: #576a94;
        font-size: 0.14rem;
        font-weight: 600;
      }
      >p:nth-child(3){
        font-size: 0.12rem;
        color: #8f8f8f;
      }
      .like{
        width: 0.12rem;
        margin: 0.06rem 0;
      }
      .common{
        margin: 0.06rem 0;
        font-size: 0.14rem;
        line-height: 0.16rem;
        color: #141414;
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        word-wrap: break-word;
        >span{
          color: #576a94;
          font-weight: 600;
        }
      }
    }
    >div:nth-child(2){
      padding: 0.1rem 0;
      background: #ededed;
      width: 0.58rem;
      display: flex;
      align-items: center;
      justify-content: center;
      max-height: 0.4rem;
      >div{
        img{
          width: 100%;
        }
        max-height: 0.4rem;
        line-height: 0.14rem;
        font-size: 0.12rem;
        text-align: center;
        overflow: hidden;
        color: #8f8f8f;
      }
    }
  }
  >img{
    width: 0.38rem;
    display: inline-block;
    height: 0.38rem;
    border-radius: 5px;
  }
}
#MessageList{
  padding: 0 0 0 0.1rem;
}
</style>

